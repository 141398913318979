<script>
import { message } from 'ant-design-vue'
import FormView from '../FormView'
import PopButton from '../PopButton'
export default {
  props: {
    data: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Array | Function,
      default: null,
    },
    isShowFoot: {
      type: Boolean,
      default: false,
    },
    foot: {
      type: Array,
      default: () => [],
    },
    success: {
      type: Function,
      default: null,
    },
    form: {
      type: Object,
      default: null,
    },
    formProps: {
      type: Object,
      default: () => {},
    },
    typeData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: this.form || {},
    }
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        this.formData = value
        this.$forceUpdate()
      },
    },
  },
  methods: {
    renderFormItem(item, index) {
      return (
        <div class={['drawer-form-item', item.class]} style={item.styles}>
          {item.title && <div style={{ marginBottom: '20px' }}>{item.title}</div>}
          <FormView
            data={item.form}
            cols={24}
            ref={'_form' + index}
            layout={'horizontal'}
            form={item.value != undefined ? item.value : this.formData}
            typeData={this.typeData}
            props={this.formProps}
          />
        </div>
      )
    },
    renderFoot() {
      return (
        <div class="drawer-foot">
          {this.foot.map((e) => {
            return <PopButton item={e} />
          })}
        </div>
      )
    },
    async sumbit() {
      const formRef = this.getData().map((e, i) => this.$refs['_form' + i])
      let obj = {}
      try {
        const subData = await Promise.all(formRef.map((e) => e.sumbit()))
        subData.forEach((e) => {
          obj = {
            ...obj,
            ...e,
          }
        })
        this.$emit('success', obj)
      } catch (error) {
        console.log('表单错误', error)
        message.error('请完善表单数据')
      }
    },
    renderBody() {
      return (
        <div class="drawer-form-body">
          {this.getData().map((e, i) => {
            return this.renderFormItem(e, i)
          })}
        </div>
      )
    },
    getData() {
      if (typeof this.data == 'function') {
        return this.data(this.formData, { update: () => this.$forceUpdate() })
      } else {
        return this.data || []
      }
    },
  },
  render() {
    return <div class="drawer-form">{this.renderBody()}</div>
  },
  renderError(h, error) {
    console.log('error', h, error)
  },
}
</script>
<style lang="less" scoped>
.drawer-form {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  height: 100%;
}
.drawer-form-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
.drawer-form-item {
  & > div:first-child {
    // margin-bottom: 20px;
    color: rgba(25, 31, 53, 1);
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba(216, 217, 222, 1);
  }
  margin-bottom: 20px;
}
/deep/ .ant-table td {
  white-space: pre-wrap;
}
</style>
